<template>
  <el-container style="height: 100%">
    <el-aside width="auto">
      <common-aside></common-aside>
    </el-aside>
    <el-container>
      <el-header>
        <common-herder></common-herder>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import CommonAside from "../src/components/CommonAside.vue";
import CommonHerder from "../src/components/CommonHerder.vue";
export default {
  name: "Main",
  components: {
    CommonAside,
    CommonHerder,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.el-header {
  background: #333;
}
</style>
