<template>
  <header>
    <div class="l-content">
      <el-button @click="handleMenu" plain :icon="icon" size="mini"></el-button>
      <el-tag
        v-for="(tag, index) in selectMenuList"
        :key="tag.path"
        :closable="index !== 0"
        :effect="$route.name == tag.name ? 'dark' : 'plain'"
        size="medium"
        style="margin-right: 10px"
        @click="clickTag(tag)"
        @close="closeTag(tag, index)"
      >
        {{ tag.label }}
      </el-tag>
    </div>
    <div class="r-content">
      <el-dropdown trigger="click" size="small" @command="logoutClick">
        <span>
          <img class="userImg" :src="userImg" alt="" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="showSystemSet" command="systemSet"
              >系统设置</el-dropdown-item
            >
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </span>
      </el-dropdown>
    </div>

    <el-dialog title="系统设置" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="设置积分奖励（按固定值）" label-width="180px">
          <el-input
            placeholder="只能输入数字，如10，就是每消费1元得10积分"
            v-model="form.integralProportionVal"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="设置积分奖励（按百分比）" label-width="180px">
          <el-input
            placeholder="只能输入数字，如40，就是得消费金额40%的积分"
            v-model="form.integralProportionPercentage"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="systemSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </header>
</template>
<script>
// import { mapState } from "vuex";
import publicData from "../../public/publicData";
import { logout, updateSystem, getSystem } from "../../api/api";
import router from "../../router";
export default {
  name: "Header",
  data() {
    return {
      form: {
        integralProportionVal: "",
        integralProportionPercentage: "",
      },
      dialogFormVisible: false,
      userImg:
        "https://www.jsfgx.online/newcar/newcar/common/pic/2e590162-8ff2-4cb2-a406-d5b557333d24.png",
      icon: "el-icon-d-arrow-left",
      selectMenuList: [],
      showSystemSet: false,
    };
  },
  computed: {
    // ...mapState({
    //   selectMenuList: (state) => state.tab.selectMenuList,
    // }),
    // selectMenuList() {
    //   console.log(JSON.parse(localStorage.getItem("selectMenuList")));
    //   return JSON.parse(localStorage.getItem("selectMenuList"));
    // },
  },
  methods: {
    // 提交系统设置
    systemSubmit() {
      this.form.id = 1;
      updateSystem(this.form).then((data) => {
        if (data.code == 0) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.dialogFormVisible = false;
            },
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 展示隐藏菜单栏
    handleMenu() {
      this.$store.commit("collapseMunu");
      if (this.icon == "el-icon-d-arrow-left") {
        this.icon = "el-icon-d-arrow-right";
      } else {
        this.icon = "el-icon-d-arrow-left";
      }
    },
    // 点击面包屑
    clickTag(tag) {
      this.$router.push(tag.name);
    },
    // 删除面包屑
    closeTag(tag, index) {
      const length = this.selectMenuList.length - 1;

      // 删除本地缓存的面包屑项
      const selectMenuList = JSON.parse(localStorage.getItem("selectMenuList"));
      const indexMenuList = selectMenuList.findIndex(
        (item) => item.name === tag.name
      );
      if (indexMenuList !== -1) {
        selectMenuList.splice(indexMenuList, 1);
      }
      localStorage.setItem("selectMenuList", JSON.stringify(selectMenuList));
      this.selectMenuList = selectMenuList;

      // this.$store.commit("removeSelectMenu", tag);

      if (tag.name !== this.$route.name) {
        return;
      }
      if (index == length) {
        this.$router.push(this.selectMenuList[index - 1].name);
      } else {
        this.$router.push(this.selectMenuList[index].name);
      }
    },
    // 下拉选择事件
    logoutClick(val) {
      if (val == "logout") {
        // 退出登录
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo) {
          logout({ token: userInfo.token }).then((data) => {
            if (data.code === 0) {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
              });
            }
          });
        }
        router.push("/login");
      } else if (val == "systemSet") {
        // 获取系统设置
        getSystem({ id: 1 }).then((data) => {
          if (data.code == 0) {
            this.form = data.data;
          }
        });
        this.dialogFormVisible = true;
      }
    },
  },
  created() {
    this.selectMenuList = JSON.parse(localStorage.getItem("selectMenuList"));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo && userInfo.type == 0) {
      this.showSystemSet = true;
    }
  },
  mounted() {
    publicData.$on("selectMenuList", (data) => {
      this.selectMenuList = data;
    });
  },
};
</script>
<style lang="less" scoped>
header {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.l-content {
  display: flex;
  margin-left: -10px;
  align-items: center;
  .el-button {
    margin-right: 20px;
  }
  .el-tag {
    cursor: pointer;
  }
}
.r-content {
  .userImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>
