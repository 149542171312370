<template>
  <el-menu
    :default-active="'/' + $route.name"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    :background-color="backgroundColor"
    :text-color="textColor"
    :active-text-color="activeTextColor"
    :unique-opened="uniqueOpened"
  >
    <h3>{{ isCollapse ? "后台" : "码上年检管理系统" }}</h3>
    <!-- 渲染没有子菜单的菜单项 -->
    <el-menu-item
      v-for="item in noChildren"
      :index="item.path"
      :key="item.path"
      @click="clickMenu(item)"
    >
      <i :class="'el-icon-' + item.icon"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>

    <!-- 渲染有子菜单的菜单项 -->
    <el-submenu v-for="item in hasChildren" :index="item.path" :key="item.path">
      <template slot="title">
        <i :class="'el-icon-' + item.icon"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group
        v-for="children in item.children"
        :key="children.path"
      >
        <el-menu-item @click="clickMenu(item)" :index="children.path">{{
          children.label
        }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
<script>
import publicData from "../../public/publicData";
export default {
  data() {
    return {
      backgroundColor: "#545c64",
      textColor: "#fff",
      activeTextColor: "#ffd04b",
      uniqueOpened: true,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 菜单点击事件，控制路由跳转
    clickMenu(item) {
      // 添加面包屑
      const selectMenuList = JSON.parse(localStorage.getItem("selectMenuList"));

      if (item.name !== "home") {
        const result = selectMenuList.findIndex(
          (val) => val.path === item.path
        );
        if (result === -1) {
          selectMenuList.push(item);
        }
      }
      localStorage.setItem("selectMenuList", JSON.stringify(selectMenuList));

      // 将更改后的值传给header组件
      publicData.$emit("selectMenuList", selectMenuList);

      //this.$store.commit("addSelectMenu", item);
      this.$router.push(item.name);
    },
  },
  // 计算属性
  computed: {
    menus() {
      return JSON.parse(localStorage.getItem("menus"));
    },
    // 筛选出没有子菜单的菜单项
    noChildren() {
      return this.menus.filter((item) => !item.children);
    },
    // 筛选出有子菜单的菜单项
    hasChildren() {
      return this.menus.filter((item) => item.children);
    },
    // 控制菜单项收缩展开
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100%;
  border: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 34px;
  }
}
</style>
